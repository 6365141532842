import './App.scss';
import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { IApiResponseContacts, IApiResponsePortfolio } from './types/types';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import HomePage from './pages/HomePage/HomePage';
import PortfolioPage from './pages/PortfolioPage/PortfolioPage';
import WeddingsPage from './pages/WeddingsPage/WeddingsPage';
import CouplesPage from './pages/CouplesPage/CouplesPage';
import useScrollToTop from './hooks/useScrollToTop';
import GalleryPage from './pages/GalleryPage/GalleryPage';
import LoadingPage from './components/LoadingPage/LoadingPage';
import useFetch from './hooks/useFetch';

function App() {

  // Fetching Data

  const {loading: loadingGontacts, error: errorGontacts, data: dataContacts} = useFetch('https://nika-backend.onrender.com/api/contact?populate=*');
  const {loading: loadingGallery, error: errorGallery, data: dataGallery} = useFetch('https://nika-backend.onrender.com/api/main-gallery?populate=*');
  const {loading: loadingSlider, error: errorSlider, data: dataSlider} = useFetch('https://nika-backend.onrender.com/api/slider-images?populate=*');
  const {loading: loadingPortfolio, error: errorPortfolio, data: dataPortfolio} = useFetch('https://nika-backend.onrender.com/api/portfolio-items?populate=*');

  // Checking Main Path And Scrolling To Top

  const [mainPage, setMainPage] = useState(false);
  const [loadingD, setLoadingD] = useState(true);

  const location = useLocation();
  const scrollToTop = useScrollToTop();

  useEffect(() => {
    if (location.pathname === '/') {
      setMainPage(true);
    } else {
      setMainPage(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!location.hash) {
      scrollToTop({ behavior: 'smooth' });
    }
  }, [location.pathname, location.hash, scrollToTop]);


  // Loader Logic

  useEffect(() => {
    setTimeout(() => {
      setLoadingD(false);
    }, 4000);
  }, []);

  useEffect(() => {
    if(loadingD) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [loadingD]);


  // Data Structure

  const contactsData = (dataContacts as unknown) as IApiResponseContacts;
  const galleryData = (dataGallery as unknown) as any;
  const sliderData = (dataSlider as unknown) as any;
  const portfolioData = (dataPortfolio as unknown) as IApiResponsePortfolio;

  const filtredWeddingsData = portfolioData?.data.filter((item: any) => item.attributes?.portfolio_type.data.attributes.Type.toLowerCase() === 'weddings')?.sort((a, b) => a.attributes.Order - b.attributes.Order);
  const filtredCouplesData = portfolioData?.data.filter((item: any) => item.attributes?.portfolio_type.data.attributes.Type.toLowerCase() === 'couples')?.sort((a, b) => a.attributes.Order - b.attributes.Order);
  const sortedSliderData = sliderData?.data.sort((a: any, b: any) => a.attributes.Order - b.attributes.Order);   
  const sortedPortfolioData = portfolioData?.data.sort((a: any, b: any) => a.attributes.Order - b.attributes.Order);  
  
  

  if(loadingGontacts || loadingSlider) return <div></div>
  if(errorGontacts || errorSlider) return <div></div>

  return (
    <div className="App" id='app'>
      <LoadingPage loading={loadingD}/>

      <Header mainPage={mainPage}/>

      <Routes>
        <Route path='/' element={<HomePage sliderImages={sortedSliderData} gallery={galleryData}/>}/>
        <Route path='/portfolio' element={<PortfolioPage data={sortedPortfolioData}/>}/>
        <Route path='/portfolio/weddings' element={<WeddingsPage data={filtredWeddingsData}/>}/>
        <Route path='/portfolio/couples' element={<CouplesPage data={filtredCouplesData}/>}/>
        <Route path='/portfolio/:slug' element={<GalleryPage data={sortedPortfolioData}/>}/>
      </Routes>

      <Footer
        InstaLink={contactsData.data.attributes.InstaLink}
        FBLink={contactsData.data.attributes.FBLink}
        EMail={contactsData.data.attributes.EMail}
        PhoneNumber={contactsData.data.attributes.PhoneNumber}
      />
    </div>
  );
}

export default App;
