
function useScrollToTopOrSection () {
    const scrollTo = (targetId?: string) => {
      if (targetId) {
        const targetElement = document.getElementById(targetId);
  
        if (targetElement) {
          targetElement.scrollIntoView({
            behavior: 'smooth',
          });
        } else {
          // eslint-disable-next-line no-console
          console.warn(`Element with id ${targetId} not found`);
        }
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    };
  
    return scrollTo;
  };

  export default useScrollToTopOrSection;