import './Footer.scss';
import { useEffect, useRef } from 'react';
import ArrowUp from '../../assets/arrow-up.svg';
import useScrollToTopOrSection from '../../hooks/useScrollToTopOrSection';
import CarrouselBox from './CarrouselBox/CarrouselBox';
import { FooterType } from '../../types/types';


const Footer = (props: FooterType) => {
    const scrollToTop = useScrollToTopOrSection();

    const btnRef = useRef<HTMLDivElement | null>(null);
    const container = useRef<HTMLDivElement>(null);
    const innerContainer = useRef<HTMLDivElement>(null);

    const delay = 15;

    const endX = useRef(window.innerWidth / 2);
    const endY = useRef(window.innerHeight / 2);

    const _x = useRef(0);
    const _y = useRef(0);

    const requestRef = useRef<number | null>(null);

    // Calculate Btn Position On Move

    const animateCursorComponent = () => {
        _x.current += (endX.current - _x.current) / delay;
        _y.current += (endY.current - _y.current) / delay;
    
        btnRef.current!.style.top = `${_y.current - (btnRef.current!.offsetHeight + btnRef.current!.offsetHeight / 2)}px`;
        btnRef.current!.style.left = `${_x.current - btnRef.current!.offsetWidth / 2}px`;

        requestRef.current! = requestAnimationFrame(animateCursorComponent);
    };

    const mouseMoveEvent = (e : any) => {
        const rect = container.current?.getBoundingClientRect();

        endX.current = e.clientX - rect!.left;
        endY.current = e.clientY - rect!.top;

    };

    const mouseEnterEvent = (e : any) => {
        btnRef.current!.style.scale = '1';
    }

    const mouseLeaveEvent = (e : any) => {
        btnRef.current!.style.scale = '0';
    }


    const scrollEvent = (e : any) => {
        const rect = container.current?.getBoundingClientRect();
        endY.current = e.clientY - rect!.top;
    }

    useEffect(() => {
        container.current?.addEventListener('mousemove', mouseMoveEvent, false);
        innerContainer.current?.addEventListener('mouseenter', mouseEnterEvent, false);
        innerContainer.current?.addEventListener('mouseleave', mouseLeaveEvent, false);
        window.addEventListener('wheel', scrollEvent, false);
        
        animateCursorComponent();

        return () => {
            container.current?.removeEventListener('mousemove', mouseMoveEvent, false);
            innerContainer.current?.removeEventListener('mouseenter', mouseEnterEvent, false);
            innerContainer.current?.removeEventListener('mouseleave', mouseLeaveEvent, false);
            window.removeEventListener('wheel', scrollEvent, false);

            cancelAnimationFrame(requestRef.current!);
        };
    });

    return (
        <footer className='footer'>
            <div className='footer-background'></div>

            <div className='footer-container'>
                <div className='footer-carrousel' >
                    <div className='footer-carrousel-container'>
                        <CarrouselBox/>
                        <CarrouselBox/>
                        <CarrouselBox/>
                        <CarrouselBox/>
                    </div>
                </div>

                <div className='footer-carrousel-button-container' ref={container}>
                    <div className='footer-carrousel-button-container-wrapper'>
                        <div className='footer-carrousel-button-border top'></div>
                        <div className='footer-carrousel-button-border bottom'></div>

                        <div className='footer-carrousel-button-container-inner' ref={innerContainer}>
                            <a className='footer-carrousel-button' href='https://www.instagram.com/nikagunchak/?hl=uk' target='blank' >
                                <div className='footer-carrousel-button-inner' ref={btnRef}>Instagram</div>
                            </a> 
                        </div>
                    </div>
                </div>
               
                <div className='footer-info-main' id='contacts'>
                    <div>
                        <h4>NIKA GUNCHAK </h4>
                        <p>The most important memories to share are those when we are truly happy, and so it is my genuine pleasure to capture these moments for you.</p>
                    </div>

                    <ul>
                        <li>
                            <a href={props.InstaLink} target='blank'>
                                <span>instagram</span>
                            </a>
                        </li>

                        <li>
                            <a href={props.FBLink} target='blank'>
                                <span>facebook</span>
                            </a>
                        </li>
                    
                        <li >
                            <a href={`mailto:${props.EMail}`} target='blank'>
                                <span>email</span>
                            </a>
                        </li>
                    </ul>

                    <a href={`tel:${props.PhoneNumber}`} target='blank'>
                        <span className='footer-phone'>+33 (7) 49 23 94 72</span>
                    </a>
                    
                </div>

                <div className='footer-info-line'></div>

                <div className='footer-info-bottom'>
                    <span className='footer-all-rights'>©2024 Nika Gunchak. All rights reserved</span>
                    
                    <a className='footer-dev-link' href='https://www.instagram.com/vania_gun/' target='blank'>
                        <span>Design & Dev: Ivan Vergun</span>
                        <div></div>
                    </a>

                    
                    <div className='footer-button-up'>
                        <div onClick={() => scrollToTop()}>
                            <span>Back top</span>
                            <img src={ArrowUp} alt={'Arrow Up'}/>
                        </div>
                    </div>
                </div>
                
            </div>
        </footer>
    )
}

export default Footer;
