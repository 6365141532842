import classNames from "classnames";
import { Link } from "react-router-dom";
import './PortfolioNav.scss';

const PortfolioNav = (props: any) => {

    // Styles

    const getNavItemClass = (text: string) => {
        return classNames('portfolio-nav-item', {
            active: props.active === text.toLowerCase()
        });
    };

    return (
        <div className='portfolio-nav'>
            <div className='portfolio-nav-container'>
                <Link to='/portfolio/weddings'>
                    <span className={getNavItemClass('weddings')}>weddings</span>
                </Link>
                
                <Link to='/portfolio/couples'>
                    <span className={getNavItemClass('couples')}>Couples</span>
                </Link>
                
                <Link to='/portfolio'>
                    <span className={getNavItemClass('all')}>All</span>
                </Link>
            </div>
        </div>
    )
}

export default PortfolioNav
