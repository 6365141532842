import PortfolioNav from '../../components/PortfolioNav/PortfolioNav';
import './PortfolioPage.scss';
import { portfolioData } from './portfolio-data';
import PortfolioItem from '../../components/PortfolioItem/PortfolioItem';
import BottomNav from '../../components/BottomNav/BottomNav';
import React, { useRef, useEffect } from 'react';


const PortfolioPage = (props: any) => {
  const container = useRef<HTMLDivElement>(null);
  const buttonRefs = useRef<HTMLButtonElement[]>([]);

  const delay = 15;

  const endX = useRef(window.innerWidth / 2);
  const endY = useRef(window.innerHeight / 2);

  const _x = useRef(0);
  const _y = useRef(0);

  const requestRef = useRef<number | null>(null); 


  // Calculate Image Position On Move

  const animateCursorComponent = () => {
    _x.current += (endX.current - _x.current) / delay;
    _y.current += (endY.current - _y.current) / delay;
    

    buttonRefs.current.forEach((item, index) => {
      // const currentButtonRef = buttonRefs.current[index];
      let currentButtonRef = item;

      if (currentButtonRef) {
        currentButtonRef.style.top = `${_y.current - currentButtonRef.offsetHeight / 2}px`;
        currentButtonRef.style.left = `${_x.current - currentButtonRef.offsetWidth / 2}px`;
      }
    });

    requestRef.current = requestAnimationFrame(animateCursorComponent);
  };

  const mouseMoveEvent = (e: any) => {
    if (container.current) {
      const rect = container.current.getBoundingClientRect();
      
      endX.current = e.clientX - rect.left;
      endY.current = e.clientY - rect.top;
    }
  };

  const scrollEvent = (e: any) => {
    if (container.current) {
      const rect = container.current.getBoundingClientRect();
      endY.current = e.clientY - rect.top;
    }
  }


  useEffect(() => {
    container.current?.addEventListener('mousemove', mouseMoveEvent, false);
    window.addEventListener('wheel', scrollEvent, false);
    
    animateCursorComponent();

    return () => {
        container.current?.removeEventListener('mousemove', mouseMoveEvent, false);
        window.removeEventListener('wheel', scrollEvent, false);

        if (requestRef.current !== null) {
          cancelAnimationFrame(requestRef.current);
        };
    };
  }, []);

  return (
    <section className='portfolio-page' >
        <PortfolioNav active='all'/>

        <div className='portfolio-section' ref={container}>
          {
            props.data && props.data.map((item: any, index: number) => (
              <PortfolioItem
                key={index}
                id={item.id}
                title={item.attributes.Title}
                slug={item.attributes.slug}
                previewImages={item.attributes.PreviewImages.data.map((image: any) => {
                  return image.attributes.url;
                })}
                ref={(el) => (buttonRefs.current[index] = el as HTMLButtonElement)}
              />
            ))
          }
        </div>

        <BottomNav active='all'/>
    </section>
  )
}

export default PortfolioPage
