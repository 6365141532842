import './HomePage.scss';
import WelcomeSection from '../../components/WelcomeSection/WelcomeSection';
import GallerySection from '../../components/GallerySection/GallerySection';
import AboutSection from '../../components/AboutSection/AboutSection';
import FormSection from '../../components/ FormSection/FormSection';

const HomePage = (props: any) => {
  return (
    <main className='home-page'>
      <WelcomeSection sliderImages={props.sliderImages}/>
      <GallerySection gallery={props.gallery}/>
      <AboutSection/>
      <FormSection/>
    </main>
  )
}

export default HomePage;
