import './GallerySection.scss';
import { Link } from 'react-router-dom';


const images = require.context('../../assets/gallery', true);
const imageList = images.keys().map((image: string) => images(image));



const GallerySection = (props: any) => {
    // const customSort = (a: string, b: string): number => {
    //     const matchA = a.match(/gallery-(\d+)/);
    //     const matchB = b.match(/gallery-(\d+)/);
       
    //     const numberA: number = matchA ? parseInt(matchA[1]) : 0;
    //     const numberB: number = matchB ? parseInt(matchB[1]) : 0;
        
    //     return numberA - numberB;
    // };
    
    
    // const sortedImages = imageList.sort(customSort);

    const sortedImages = props.gallery && props.gallery?.data.attributes.Gallery.data?.map((image: any) => {
        return image.attributes.url;
    }); 

    return (
        <section className='gallery-section'>
            <div className='gallery-section-container'>
                {
                    sortedImages?.map((image: any, index: number) => (
                        <div className='gallery-image' key={index}>
                            <img src={image} alt={`gallery-${index}`}/>
                        </div>
                    ))
                }

                <Link to='/portfolio'>
                    <button className='gallery-section-button'>
                        <span>view all</span>
                    </button>
                </Link>
            </div>
        </section>
    )
}

export default GallerySection
