import './GalleryPage.scss';
import { useRef, useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { portfolioData } from "../PortfolioPage/portfolio-data";
import { PortfolioItemType } from "../../types/types";
import BACK_ICON from '../../assets/back-icon.svg';
import STAR_ICON from '../../assets/star-icon-3.svg';


// Calculate Image Width In Rows

const getImageWidth = (row: any[], img: any) => {
    const numVerticals = row.filter(i => i.boxClass === 'vertical').length;
    const numHorizontals = row.filter(i => i.boxClass === 'horizontal').length;


    if (window.innerWidth < 720) {
        if (row.length === 2 && numVerticals === 2) {
            return 'calc(50% - 6px)';
        } else if (row.length === 1 && numHorizontals === 1) {
            return '100%';
        } else if (row.length === 1 && numVerticals === 1) {
            return '100%';
        }
    } else {
        if (row.length === 3 && numVerticals === 3) {
            return 'calc(33.3% - 8px)';
        } else if (row.length === 2 && numHorizontals === 2) {
            return img.boxClass === 'horizontal' ? (row.indexOf(img) === 0 ? 'calc(47% - 6px)' : 'calc(53% - 6px)') : 'auto';
        } else if (row.length === 3 && numVerticals === 2 && numHorizontals === 1) {
            return img.boxClass === 'horizontal' ? 'calc(50% - 8px)' : 'calc(25% - 8px)';
        } else if (row.length === 2 && numVerticals === 1 && numHorizontals === 1) {
            return img.boxClass === 'horizontal' ? 'calc(74% - 6px)' : 'calc(26% - 6px)';
        }
    }

    return 'auto';
};


// Calculate Row Height

const getRowHeight = (row: any[]) => {
    const numVerticals = row.filter(img => img.boxClass === 'vertical').length;
    const numHorizontals = row.filter(img => img.boxClass === 'horizontal').length;

    if (window.innerWidth < 720 && window.innerWidth > 600) {
        if (row.length === 2 && numVerticals === 2) {
            return 450;
        } else if (row.length === 1 && numHorizontals === 1) {
            return 400;
        } else if (row.length === 1 && numVerticals === 1) {
            return 700;
        }
    } else if (window.innerWidth < 600 && window.innerWidth > 450) {
        if (row.length === 2 && numVerticals === 2) {
            return 380;
        } else if (row.length === 1 && numHorizontals === 1) {
            return 280;
        } else if (row.length === 1 && numVerticals === 1) {
            return 600;
        }
    } else if (window.innerWidth < 450) {
        if (row.length === 2 && numVerticals === 2) {
            return 270;
        } else if (row.length === 1 && numHorizontals === 1) {
            return 228;
        } else if (row.length === 1 && numVerticals === 1) {
            return 500;
        }
    } else if (window.innerWidth > 1680 && window.innerWidth < 1750) {
        if (row.length === 3 && numVerticals === 3) {
            return 700;
        } else if (row.length === 2 && numHorizontals === 2) {
            return 470;
        } else if (row.length === 3 && numVerticals === 2 && numHorizontals === 1) {
            return 600;
        } else if (row.length === 2 && numVerticals === 1 && numHorizontals === 1) {
            return 650;
        }
    } else if (window.innerWidth > 1750) {
        if (row.length === 3 && numVerticals === 3) {
            return 750;
        } else if (row.length === 2 && numHorizontals === 2) {
            return 470;
        } else if (row.length === 3 && numVerticals === 2 && numHorizontals === 1) {
            return 600;
        } else if (row.length === 2 && numVerticals === 1 && numHorizontals === 1) {
            return 650;
        }
    } else if (window.innerWidth < 1200 && window.innerWidth > 1000) {
        if (row.length === 3 && numVerticals === 3) {
            return 550;
        } else if (row.length === 2 && numHorizontals === 2) {
            return 350;
        } else if (row.length === 3 && numVerticals === 2 && numHorizontals === 1) {
            return 400;
        } else if (row.length === 2 && numVerticals === 1 && numHorizontals === 1) {
            return 570;
        }
    } else if (window.innerWidth < 1000 && window.innerWidth > 720) {
        if (row.length === 3 && numVerticals === 3) {
            return 450;
        } else if (row.length === 2 && numHorizontals === 2) {
            return 270;
        } else if (row.length === 3 && numVerticals === 2 && numHorizontals === 1) {
            return 300;
        } else if (row.length === 2 && numVerticals === 1 && numHorizontals === 1) {
            return 380;
        }
    } else {
        if (row.length === 3 && numVerticals === 3) {
            return 660;
        } else if (row.length === 2 && numHorizontals === 2) {
            return 420;
        } else if (row.length === 3 && numVerticals === 2 && numHorizontals === 1) {
            return 480;
        } else if (row.length === 2 && numVerticals === 1 && numHorizontals === 1) {
            return 620;
        }
    }

    return Math.max(...row.map(img => img.boxClass === 'horizontal' ? 420 : 620));
};


// Create Rows

const generateRows = (imageInfo: any[]) => {
    const rows: any[] = [];
    let currentRow: any[] = [];
    let remainingImages: any[] = imageInfo.slice();

    const isValidRow = (row: any[]) => {
        const numVerticals = row.filter(img => img.boxClass === 'vertical').length;
        const numHorizontals = row.filter(img => img.boxClass === 'horizontal').length;

        if (window.innerWidth < 720) {
            return (
                (row.length === 2 && numVerticals === 2) || 
                (row.length === 1 && numHorizontals === 1) || 
                (row.length === 1 && numVerticals === 1 && (!remainingImages[0] || remainingImages[0].boxClass === 'horizontal')) 
            );
        } else {
            return (
                (row.length === 3 && numVerticals === 3) || 
                (row.length === 2 && numHorizontals === 2) || 
                (row.length === 3 && numVerticals === 2 && numHorizontals === 1) || 
                (row.length === 2 && numVerticals === 1 && numHorizontals === 1)
            );
        }
    };

    const canAddToRow = (row: any[], nextImage: any) => {
        const numVerticals = row.filter(img => img.boxClass === 'vertical').length;
        const numHorizontals = row.filter(img => img.boxClass === 'horizontal').length;

        if (window.innerWidth < 720) {
            return row.length < 2;
        } else {
            return (
                row.length < 3 &&
                !(row.length === 2 && numVerticals === 1 && numHorizontals === 1 && nextImage && nextImage.boxClass !== 'vertical')
            );
        }
    };

    while (remainingImages.length > 0) {
        const image = remainingImages.shift();
        currentRow.push(image);

        const nextImage = remainingImages[0];
        if (!canAddToRow(currentRow, nextImage)) {
            if (isValidRow(currentRow)) {
                rows.push({ images: currentRow, height: getRowHeight(currentRow) });
                currentRow = [];
            } else {
                remainingImages.unshift(currentRow.pop());
                rows.push({ images: currentRow, height: getRowHeight(currentRow) });
                currentRow = [];
            }
        }
    }

    if (currentRow.length > 0) {
        rows.push({ images: currentRow, height: getRowHeight(currentRow) });
    }

    return rows;
};


const GalleryPage = (props: any) => {
    const { slug } = useParams();
    const navigate = useNavigate();

    const [imageClasses, setImageClasses] = useState<string[]>([]);
    const [imageBoxClasses, setImageBoxClasses] = useState<string[]>([]);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [pageLoaded, setPageLoaded] = useState(false);

    const refImages = useRef<(HTMLImageElement | null)[]>([]);

    useEffect(() => {
        setTimeout(() => {
            if (imageLoaded) {
                const newImageClasses: string[] = [];
                const newImageBoxClasses: string[] = [];

                refImages.current.forEach((image, index) => {
                    if (image) {
                        if (image.width > image.height) {
                            newImageBoxClasses[index] = 'horizontal';
                            newImageClasses[index] = 'gallery-page-images-item';
                        } else if (image.height > image.width) {
                            newImageBoxClasses[index] = 'vertical';
                            newImageClasses[index] = 'gallery-page-images-item';
                        }
                    }
                });

                setImageClasses(newImageClasses);
                setImageBoxClasses(newImageBoxClasses);
            }
        }, 1000);
    }, [imageLoaded]);

    useEffect(() => {
        setTimeout(() => {
            setPageLoaded(true);
        }, 1000)
    }, [])

    const handleBack = () => {
        navigate(-1);
    };

    const handleImageLoad = (index: number) => {
        return () => {
            setImageLoaded(true);
        };
    };

    const currentPortfolio = props.data && props.data.filter((item: any) => item.attributes.slug === slug);

    if (!currentPortfolio || currentPortfolio.length === 0) {
        return <div></div>;
    }

    // const previewImages = currentPortfolio[0] && currentPortfolio[0].attributes.PreviewImages.data?.map((image: any) => {
    //     return image.attributes.url;
    // });

    const galleryImages = currentPortfolio[0] && currentPortfolio[0].attributes.Gallery.data?.map((image: any) => {
        return image.attributes.url;
    });

    const imageInfo = galleryImages.map((image: any, index: number) => {
        return {
            url: image,
            index,
            class: imageClasses[index],
            boxClass: imageBoxClasses[index]
        };
    });

    const galleryImageElements = generateRows(imageInfo).map((row: any, rowIndex: number) => (
        <div className="gallery-row" key={rowIndex} style={{ height: row.height, width: '100%' }}>
            {row.images.map((image: any) => (
                <div className={image.boxClass} key={image.index} style={{ width: getImageWidth(row.images, image) }}>
                    <img
                        ref={(el) => (refImages.current[image.index] = el)}
                        className={image.class}
                        src={image.url}
                        alt="gallery"
                        onLoad={handleImageLoad(image.index)}
                    />
                </div>
            ))}
        </div>
    ));


    // const allImages = previewImages.concat(galleryImages);

    // if(pageLoaded) return <div></div>

    return (
        <main className='gallery-page'>
            <section className='gallery-page-container'>
                <div className='gallery-page-title'>
                    <div className='gallery-page-title-part first'>
                        <div className='gallery-page-back-btn' onClick={handleBack}>
                            <img src={BACK_ICON} alt={'back'} />
                            <span>back</span>
                        </div>
                        <img src={STAR_ICON} alt={'star'} />
                        <h2>{currentPortfolio[0] && currentPortfolio[0].attributes.Title}</h2>
                    </div>
                    <div className='gallery-page-title-part second'>
                        <span>{currentPortfolio[0] && currentPortfolio[0].attributes.Place}</span>
                        <img src={STAR_ICON} alt={'star'} />
                        <span>{currentPortfolio[0] && currentPortfolio[0].attributes.Date}</span>
                    </div>
                    <div className='gallery-page-title-border'></div>
                </div>

                <div className='gallery-page-images-box'>
                    <div className={`gallery-page-images-box-preloader ${pageLoaded && 'hidden'}`}></div>

                    {galleryImageElements}
                </div>
            </section>
        </main>
    );
};

export default GalleryPage;
