import './AboutSection.scss';
import NIKAPHOTO from '../../assets/nika-gunchak.webp';

const AboutSection = () => {
    return (
        <section className='about-section' id='about'>
            <div className='about-section-container'>
                <div className='about-section-biography'>
                    <div className='biography-title'>
                        <h1>Nika GUNCHAK</h1>
                        <h2>destination WEDDING PHOTOGRAPHER</h2>
                    </div>

                    <div className='biography-text'>
                        <p>I prefer a documentary and elegant style of photography with an editorial touch. It’s  very relaxed, non-intrusive, and easygoing approach on your wedding day, but without sacrificing the quality and look of your photos. </p>
                        <p>A new shoot for me is a unique experience spent with a couple creating their own special story. </p>
                    </div>

                    <span>Photography is my passion for more than 10 years. </span>

                    <div className='about-section-image'>
                        <img src={NIKAPHOTO} alt={'Nika Gunchak'}/>
                    </div>
                </div>

                <div className='about-section-quote'>
                    <p>My work is about getting to know you, to connect with you and to understand you. Then, it becomes possible to create meaningful, sincere and elegant images.</p>

                    <span>© Nika Gunchak</span>
                </div>
            </div>
        </section>
    )
}

export default AboutSection;
