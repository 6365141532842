import './PortfolioItem.scss';
import React, { forwardRef, Ref } from 'react';
import { PortfolioItemType } from "../../types/types";
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { Link } from 'react-router-dom';

const PortfolioItem = forwardRef((props: PortfolioItemType, ref: Ref<HTMLButtonElement>) => {
    // const isMobile = useMediaQuery('(max-width: 1000px)');
    const isAnimatePreview = useMediaQuery('(max-width: 800px)');

    return (
        <div className='portfolio-item'>
           <div className='portfolio-item-title'>
                <h3>{props.title}</h3>
                <div></div>
            </div>

 
            <div className='portfolio-item-preview'>
            
                {
                    <div className='portfolio-item-preview-box'>
                        {
                            props.previewImages?.map((image, index) => (
                                <div key={index} className='portfolio-item-image'>
                                    <img src={image} alt={'preview'} loading='lazy'/>
                                </div>
                            ))
                        }
                    </div>
                }

                {
                    isAnimatePreview && (
                        <div className='portfolio-item-preview-box'>
                            {props.previewImages?.map((image, index) => (
                                <div key={index} className='portfolio-item-image'>
                                    <img src={image} alt={'preview'} loading='lazy'/>
                                </div>
                            ))}
                        </div >
                    )
                }

                {
                    isAnimatePreview && (
                        <div className='portfolio-item-preview-box'>
                            {props.previewImages?.map((image, index) => (
                                <div key={index} className='portfolio-item-image'>
                                    <img src={image} alt={'preview'}/>
                                </div>
                            ))}
                        </div>
                    )
                }

                {
                    isAnimatePreview && (
                        <div className='portfolio-item-preview-box'>
                            {props.previewImages?.map((image, index) => (
                                <div key={index} className='portfolio-item-image'>
                                    <img src={image} alt={'preview'}/>
                                </div>
                            ))}
                        </div>
                    )
                }
            

                <Link to={`/portfolio/${props.slug}`}>
                    <button className='portfolio-item-button' ref={ref}>
                        <span>view</span>
                    </button>
                </Link>
            </div>

            <Link to={`/portfolio/${props.slug}`} >
                <button className='portfolio-item-button-mobile'>
                    <span>view all</span>
                </button>
            </Link> 
        </div>
    )
})

export default PortfolioItem
