import './Header.scss';
import LOGO from '../../assets/logo-white.png';
import CloseIcon from '../../assets/close-icon.svg';
import OpenIcon from '../../assets/open-icon.svg';
import { useState, useEffect} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import useScrollDirection from '../../hooks/useScrollDirection';
import classNames from 'classnames';


const Header = (props: any) => {
    const [openMenu, setOpenMenu] = useState(false);
    const [headerOpen, setHeaderOpen] = useState(false);
    const [visible, setVisible] = useState(true);

    const isMobile = useMediaQuery('(max-width: 720px)');
    const scrollDirection = useScrollDirection();

     // Open/Close Menu Logic

     const handleMenuOpen = () => {
        if (isMobile) {
            setOpenMenu(true);
            setHeaderOpen(true);
        } 
    };

    const handleMenuClose = () => {
        if (isMobile) {
            setOpenMenu(false);

            setTimeout(() => {
                setHeaderOpen(false)
            }, 900);
        } 
    };

    useEffect(() => {
        if (openMenu) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'unset';
        }
    
        return () => {
          document.body.style.overflow = 'unset';
        };
    }, [openMenu]);

    useEffect(() => {
        const timerId = setTimeout(() => {
            setVisible(false);
        }, 2000);
          
        return () => {
            clearTimeout(timerId);
        };
    }, []) 

    // Styles

    const headerStyles = classNames('header', {
        hide: scrollDirection === 'down',
        open: headerOpen
    });

    const navItemsStyles = classNames('nav-items', {
        open: headerOpen
    });

    const openIconClasses = classNames('open-icon', {
        open: openMenu
    });

    const closeIconClasses = classNames('close-icon', {
        open: openMenu
    });

    const navigationClasses = classNames('header-navigation', {
        open: openMenu,
        visible: visible
    });

    return (
        <header className={headerStyles}>
            <div className='header-container'>
                <div className='header-logo'>
                    <Link to='/'>
                        <img src={LOGO} alt={'Logo'}/>
                    </Link>
                </div>

                <nav className={navigationClasses}>
                    <ul>
                        <li className={navItemsStyles}>
                            <Link to='/portfolio' onClick={handleMenuClose}>
                                <span>Portfolio</span>
                            </Link>
                        </li>

                        {
                            props.mainPage ?
                            (<li  className={navItemsStyles}>
                                <ScrollLink smooth spy to='about' onClick={handleMenuClose}>
                                    <span>about</span>
                                </ScrollLink>
                            </li>) :
                            null
                        }

                        <li  className={navItemsStyles}>
                            <ScrollLink smooth spy to='contacts' onClick={handleMenuClose}>
                                <span>contacts</span>
                            </ScrollLink>
                        </li>


                        {/* <li className={navItemsStyles} className='hidden' onClick={handleMenuClick}>
                            <a href='https://www.instagram.com/nikagunchak/?hl=uk' target='blank'>
                                <span>instagram</span>
                            </a>
                        </li>

                        <li className={navItemsStyles} className='hidden' onClick={handleMenuClick}>
                            <a href='https://www.facebook.com/nika.gounchak' target='blank'>
                                <span>facebook</span>
                            </a>
                        </li>
                    
                        <li className={navItemsStyles} className='hidden' onClick={handleMenuClick}>
                            <a href='mailto:nikusya24@gmail.com' target='blank'>
                                <span>email</span>
                            </a>
                        </li> */}
                    
                        
                    </ul>
                </nav>

                <img className={openIconClasses} onClick={handleMenuOpen} src={OpenIcon} alt={'Open Menu Icon'}/>

                <img className={closeIconClasses} onClick={handleMenuClose} src={CloseIcon} alt={'Close Menu Icon'}/>
            </div>
        </header>
    )
}

export default Header;
