import Carousel from './Carousel/Carousel';
import './WelcomeSection.scss';
import ArrowDown from '../../assets/arrow-down.svg';

const WelcomeSection = (props: any) => {
    return (
        <section className='welcome-section'>
            <div className='welcome-section-container'>
                <div className='welcome-section-slider'>
                    <Carousel sliderImages={props.sliderImages}/>
                </div>

                <span className='welcome-section-text first'>special</span>

                <span className='welcome-section-text second'>sTORY</span>

                <span className='welcome-section-text third'>FOR EVERyone </span>

                <span className='welcome-section-text fourth'>Every picture tells a story... Let me help you tell yours</span>

                <span className='welcome-section-text fifth'>Nika Gunchak Photography</span>

                <div className='welcome-portfolio-pointer'>
                    <span>portfolio</span>
                    <img src={ArrowDown} alt={'Arrow Down'}/>
                </div>
            </div>
        </section>
  )
}

export default WelcomeSection

