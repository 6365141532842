import './LoadingPage.scss';
import LOGO from '../../assets/logo.png';
import { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

const LoadingPage = (props : any) => {
    const images = require.context('../../assets/loader', true);
    const imageList = images.keys().map((image: string) => images(image));

    const [filled, setFilled] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const loadingPageRef = useRef<HTMLDivElement>(null);

    const getRandomWidth = () => {
        return Math.floor(Math.random() * (285 - 195 + 1)) + 195;
    };


    useEffect(() => {
        if(!props.loading) {
            loadingPageRef.current!.style.animation = 'logoPageHide 2s forwards';
        }
    }, [props.loading]);

    useEffect(() => {
        if (filled < 100 && props.loading) {
            setTimeout(() => setFilled(prev => prev += 2), 60)
        }
    }, [filled, props.loading]);

    useEffect(() => {
        if (props.loading) {
            const interval = setInterval(() => {
                const img = document.createElement('img');
                img.src = imageList[currentIndex];
                img.alt = `Loading ${currentIndex}`;
                img.className = 'loading-image';
                img.style.maxWidth = `${getRandomWidth()}px`;
                loadingPageRef.current?.appendChild(img);
                setCurrentIndex((prevIndex) => (prevIndex + 1) % imageList.length);
            }, 300);

            return () => clearInterval(interval);
        } 
    }, [props.loading, currentIndex]);

    return (
        <>
           {    
                ReactDOM.createPortal(
                    <section className='loading-page' ref={loadingPageRef}>
                        <div className='loading-logo'> 
                            <img src={LOGO} alt={'Logo'}/>
                        </div>

                        <div className='loading-progress-bar'>
                            <span className='loading-progress-percentage' style={{ marginLeft: `calc(${filled}% - 65px)`}}>{filled}%</span>
                            <div className='loading-progress-line' style={{width: `${filled}%`}}></div>
                        </div>

                        {/* {   
                            displayedImages.map((image, index) => (
                                <img className='loading-image' key={index} src={image} alt={`Loading ${index}`} style={{ maxWidth: `${getRandomWidth()}px` }}/>
                            ))
                        } */}

                    </section>,
                    document.getElementById('loader') || document.body
                )
            }
        </>  
    )
}

export default LoadingPage
