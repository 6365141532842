import './Form.scss';
import { useState, FormEvent, ChangeEvent, useRef } from 'react';
import ALERT_ICON from '../../../assets/alert-icon.svg';
import DATE_ICON from '../../../assets/calendar.svg';
import classNames from 'classnames';
import PhoneInput from 'react-phone-input-2';
import { InputMask } from 'primereact/inputmask';
import { FormData, ErrorsData, CountryData, FocusInputsData } from '../../../types/types';
import 'react-phone-input-2/lib/style.css';
import { phones } from './phones';
import emailjs from '@emailjs/browser';

type InputMaskChangeEvent = ChangeEvent<HTMLInputElement>;

const Form = () => {
    const formRef = useRef<HTMLFormElement | null>(null);

    const focusInputs: FocusInputsData = {
        name: false,
        email: false,
        message: false,
        fullMessage: false,
        city: false,
        date: false
    }

    const initialFormData: FormData = {
        name: '',
        email: '',
        message: '',
        city: '',
        date: '',
    };

    const initialErrorsData: ErrorsData = {
        name: false,
        email: false,
        emptyEmail: false,
        message: false,
        city: false,
        emptyCity: false,
        date: false,
        emptyDate: false,
    };

    const [focus, setFocus] = useState(focusInputs);
    const [focusPhone, setFocusPhone] = useState(false);

    const [formData, setFormData] = useState<FormData>(initialFormData);
    const [phone, setPhone] = useState<string>();

    const [errors, setErrors] = useState<ErrorsData>(initialErrorsData);
    const [phoneError, setPhoneError] = useState<boolean>(false);

    const [countryCode, setCountryCode] = useState<string>('');
    const [country, setCountry] = useState<string | undefined>('ua')

    const [firstSubmit, setFirstSubmit] = useState<boolean>(true);


    // Sending Message On Mail

    function sendEmail(e: any) {
        e.preventDefault();

        // const bodyMessage = `
        //     Name: ${formData.name}<br/>
        //     Email: ${formData.email}<br/>
        //     Phone: ${phone}<br/>
        //     Place: ${formData.city}<br/>
        //     Date: ${formData.date}<br/>
        //     Message: ${formData.message}
        // `;

        // window.Email.send({
        //     Host : "smtp.elasticemail.com",
        //     Username : "oleg252.og@gmail.com",
        //     Password : "5AEF223277D3C5E63AEFCEA469F945B75BF4",
        //     To : 'oleg252.og@gmail.com',
        //     From : "oleg252.og@gmail.com",
        //     Subject : "Message",
        //     Body : bodyMessage
        // }).then(
        //   (message: string) => console.log(message)
        // );

        if (formRef.current) {
            emailjs.sendForm('service_34p48at', 'template_o5ogb98', formRef.current, {publicKey: 'CbBi2lkV89DHxVHv6'}).then(
                (response) => {
                    console.log('SUCCESS!', response.status, response.text);
                },
                (error) => {
                    console.log('FAILED...', error);
                },
            );
        } 
    };


    // Validation For Date

    const isFutureDate = (date : any): boolean =>{
        let today = new Date().getTime();
        date = date.split(".");
        
        date = new Date(date[2], date[1] - 1, date[0]).getTime();
        return (today - date) < 0;
    }


    // Validation For Inputs And Phone Separately

    const validatePhone = (value: string, country: string) => {
        const phonePattern = phones[country];

        if (value || country) {
            setPhoneError(!phonePattern || !phonePattern.test(value));
        }
    };

    const validateForm = (id?: keyof FormData, value?: string | undefined) => {
        let newErrors = { ...errors };
        
        if (!id) {
            newErrors.name = !formData.name.trim();
            newErrors.email = formData.email.trim() ? !/[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}/.test(formData.email) : false;
            newErrors.emptyEmail = !formData.email.trim();
            newErrors.message = !(formData.message.trim().length < 500);
            newErrors.city = formData.city.trim() ? formData.city.trim().length < 2 : false;
            newErrors.emptyCity = !formData.city.trim();
            // newErrors.date = formData.date.trim() ? !(!isNaN(new Date(String(formData.date)).getTime()) && new Date(String(formData.date)).getTime() > Date.now()) : false;
            newErrors.date = !isFutureDate(formData.date.trim());
            newErrors.emptyDate = !formData.date.trim();
        } else if (id === 'name') {
            newErrors.name = !value?.trim();
        } else if (id === 'email') {
            newErrors.email = !/[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}/.test(value ?? '');
            newErrors.emptyEmail = !value?.trim();
        } else if (id === 'message') {
            newErrors.message = !((value ?? '').trim()?.length < 500);
        } else if (id === 'city') {
            newErrors.city = value?.trim() ? value.trim().length < 2 : false;
            newErrors.emptyCity = !value?.trim();
        } else if (id === 'date') {
            newErrors.date = !isFutureDate((value ?? '').trim());
            // newErrors.date = value?.trim() ? !(!isNaN(new Date(String(value)).getTime()) && new Date(String(value)).getTime() > Date.now()) : false;
            newErrors.emptyDate = !value?.trim();
        }
        
        setErrors(newErrors);
    
        return newErrors;
    };


    // Changing On Typing In Inputs And Phone Separately

    const handleChangeForPhone = (value: string, country: CountryData) => {
        setPhone(value);

        if (!firstSubmit) {
            validatePhone(value, country.countryCode!);
            setCountry(country.countryCode);
        }

        setCountryCode(country.dialCode);
    };


    const handleChange = (e: any) => {
        const {id, value} = e.target;

        setFormData({
            ...formData, [id] : value
        });

        if (!firstSubmit) {
            validateForm(id as keyof FormData, value);
        }
    }


    // Submiting Form

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (firstSubmit) {
            setFirstSubmit(false);
        }

        const phoneInput = (e.target as HTMLFormElement).querySelector<HTMLInputElement>('#phone');
        const currentPhone = phoneInput?.value?.replace(/[\+\(\)\s]/g, '') || '';

        validatePhone(currentPhone, country || '');

        let newErrors = validateForm();
    
        if(JSON.stringify(Object.values(newErrors)) === JSON.stringify(Object.values(initialErrorsData)) && !phoneError) {
            sendEmail(e);
            setFirstSubmit(false);
            setPhone(countryCode);
            setFormData(initialFormData);
            setPhoneError(false);
            // (e.target as HTMLFormElement).reset();
            e.currentTarget.reset();
        }
    };

    // Styles

    const labelNameStyles = classNames('input-label', {
        name: focus.name || formData.name.length,
        error: errors.name
    });

    const labelEmailStyles = classNames('input-label', {
        email: focus.email || formData.email.length,
        error: errors.email || errors.emptyEmail
    });

    const labelMessageStyles = classNames('input-label', {
        message: focus.message,
        fullMessage: focus.fullMessage,
        error: errors.message
    });

    const labelCityStyles = classNames('input-label', {
        city: focus.city || formData.city.length,
        error: errors.city || errors.emptyCity
    });
    
    const labelDateStyles = classNames('input-label', {
        date: focus.date,
        error: errors.date || errors.emptyDate
    });

    const phoneLabelsStyles = classNames('input-label-phone', {
        focus: focusPhone,
        error: phoneError
    });

    return (
        <div className='form-block'>
            <p className='form-block-text'>Please take a moment to fill out the form, and I’ll make it a priority to respond you within 48 hours.</p>
            <span className='form-block-warning-text'>*Sending the message you agree to the Privacy Policy</span>

            <form onSubmit={handleSubmit} ref={formRef}>
                <div className={`input-field ${errors.name && 'error'}`}>
                    <input 
                        type='text' 
                        name='user_name'
                        placeholder='Enter your name' 
                        id='name' 
                        className={`input-item ${errors.name && 'error'}`}
                        autoComplete='off'
                        onFocus={() => {setFocus({...focus, name: true})}}
                        onBlur={() => {
                            if (initialFormData.name === formData.name) {
                                setFocus({...focus, name: false});
                            }
                        }}
                        onChange={handleChange}
                    />
                    <div className={`err-txt ${errors.name && 'error'}`}>This field is required</div>

                    <span className={labelNameStyles}>Name*</span>

                    <img className={`err-icon ${errors.name && 'error'}`} src={ALERT_ICON} alt={'alert'}/>
                </div>

                <div className={`input-field ${phoneError && 'error'}`}>
                    <PhoneInput
                        country={country}
                        countryCodeEditable={false}
                        value={phone}
                        onChange={(value, country: CountryData) => {
                            handleChangeForPhone( value, country);
                        }}
                        onFocus={() => {setFocusPhone(true)}}
                        onBlur={() => {
                            if (phone === countryCode || phone === '') {
                                setFocusPhone(false);
                            }
                        }}
                        inputProps={{
                            name: 'user_phone',
                            id: 'phone',
                            className: `input-item phone ${phoneError && 'error'}`
                        }}
                    />  
                    <div className={`err-txt ${phoneError && 'error'}`}>Fill in the field correctly</div>

                    <span className={phoneLabelsStyles} onClick={() => {setFocusPhone(true)}}>Phone*</span>

                    <img className={`err-icon ${phoneError && 'error'}`} src={ALERT_ICON} alt={'alert'}/>
                </div>   
                
                <div className={`input-field ${(errors.email || errors.emptyEmail) && 'error'}`}>
                    <input 
                        type='text' 
                        placeholder='example@mail.com' 
                        name='user_email'
                        id='email' 
                        className={`input-item ${(errors.email || errors.emptyEmail) && 'error'}`}
                        autoComplete='off'
                        onFocus={() => {setFocus({...focus, email: true})}}
                        onBlur={() => {
                            if (initialFormData.email === formData.email) {
                                setFocus({...focus, email: false});
                            }
                        }}
                        onChange={handleChange}
                    />
                    <div className={`err-txt ${(errors.email || errors.emptyEmail) && 'error'}`}>
                        {
                            errors.email ? (<>Fill in the field correctly</>)
                                : errors.emptyEmail ? (<>This field is required</>)
                                : null
                        }
                    </div>

                    <span className={labelEmailStyles}>Email*</span>

                    <img className={`err-icon ${(errors.email || errors.emptyEmail) && 'error'}`} src={ALERT_ICON} alt={'alert'}/>
                </div>

                <div className={`input-field ${(errors.date || errors.emptyDate) && 'error'}`}>
                    <InputMask 
                        type='text' 
                        placeholder='mm.dd.yyyy' 
                        name='user_date'
                        id='date' 
                        className={`input-item ${(errors.date || errors.emptyDate) && 'error'}`}
                        autoComplete='off'
                        onFocus={() => {setFocus({...focus, date: true})}}
                        onBlur={() => {
                            if (initialFormData.date === formData.date) {
                                setFocus({...focus, date: false});
                            }
                        }}
                        onChange={handleChange}
                        mask='99.99.9999'
                        slotChar='mm.dd.yyyy'
                    />
                    <div className={`err-txt ${(errors.date || errors.emptyDate) && 'error'}`}>
                        {
                            errors.date ? (<>Fill in the field correctly</>)
                                : errors.emptyDate ? (<>This field is required</>)
                                : null
                        }
                    </div>

                    <span className={labelDateStyles}>Date*</span>

                    <img className={`err-icon ${(errors.date || errors.emptyDate) && 'error'}`} src={ALERT_ICON} alt={'alert'}/>

                    <img className={`date-icon ${(errors.date || errors.emptyDate) && 'error'}`} src={DATE_ICON} alt={'date'}/>
                </div>

                {/* <div className='input-field'>
                    <input
                        name=''
                        type='date' 
                        id='date'
                        placeholder='dd.mm.yyyy'
                        className='input-item'
                        autoComplete='off'
                        required
                        onFocus={() => {setFocus({...focus, date: true})}}
                        onBlur={() => {
                            if (initialFormData.date === formData.date) {
                                setFocus({...focus, date: false});
                            }
                        }}
                        onChange={handleChange}
                    />
                    {/* <div className={`err-txt ${errors.message && 'error'}`}>Message can't be blank</div> */}

                    {/* <span className={labelDateStyles}>Date*</span>
                </div> */} 

                <div className={`input-field ${(errors.city || errors.emptyCity) && 'error'}`}>
                    <input
                        type='text' 
                        name='user_city'
                        id='city'
                        placeholder='Enter the city'
                        className={`input-item ${(errors.city || errors.emptyCity) && 'error'}`}
                        autoComplete='off'
                        onFocus={() => {setFocus({...focus, city: true})}}
                        onBlur={() => {
                            if (initialFormData.city === formData.city) {
                                setFocus({...focus, city: false});
                            }
                        }}
                        onChange={handleChange}
                    />
                    <div className={`err-txt ${(errors.city || errors.emptyCity) && 'error'}`}>
                        {
                            errors.city ? (<>Fill in the field correctly</>)
                                : errors.emptyCity ? (<>This field is required</>)
                                : null
                        }
                    </div>

                    <span className={labelCityStyles}>Where all will be happening?*</span>

                    <img className={`err-icon ${(errors.city || errors.emptyCity) && 'error'}`} src={ALERT_ICON} alt={'alert'}/>
                </div>

                <div className={`input-field ${errors.message && 'error'} textarea`}>
                    <textarea
                        name='user_message'
                        // type='' 
                        id='message'
                        maxLength={500}
                        placeholder='Your message'
                        className={`input-item ${errors.message && 'error'} textarea`}
                        autoComplete='off'
                        onFocus={() => {setFocus({...focus, message: true, fullMessage: false})}}
                        onBlur={() => {
                            if (initialFormData.message === formData.message) {
                                setFocus({...focus, message: false});
                            } else {
                                setFocus({...focus, fullMessage: true});
                            }
                        }}
                        onChange={handleChange}
                    />
                    <div className={`err-txt ${errors.message && 'error'}`}>You have reached 500 characters</div>

                    <span className={`${labelMessageStyles} textarea`}>Tell me about you and your plan</span>

                    <img className={`err-icon ${errors.message && 'error'}`} src={ALERT_ICON} alt={'alert'}/>
                </div>

                <button type='submit'>send a message</button>
            </form>
        </div>
    )
}

export default Form
