import './Carousel.scss';
import { useState, useEffect } from 'react';

const Carousel = (props: any) => {
    const [imageData, setImageData] = useState<string[]>([]);
    const [currentIndex, setCurrentIndex] = useState(1);
    const [prevIndex, setPrevIndex] = useState(0);


    // Getting URLs For Images

    const extractUrls = () => {
        const urls = props.sliderImages.map((sliderImage: any) => {
            return sliderImage.attributes.Image.data.attributes.url;
        });
        setImageData(urls);
    };

    useEffect(() => {
        extractUrls();
    }, []);


    // Slider Logic

    useEffect(() => {
        setTimeout(() => {
            setPrevIndex(1);
        }, 3000);

        const interval = setInterval(() => {
            moveNext();
        }, 4000);
    
        return () => {
            clearInterval(interval);
        };
    }, []);

    
    const moveNext = () => {
        setCurrentIndex((prev) => (prev + 1) % 5);

        setTimeout(() => {
            setPrevIndex((prev) => (prev + 1) % 5);
        }, 3000);
    };

    return (
        <div className='carousel'>
            <div className='carousel-container'>
                {
                    imageData.length && imageData.map((image: string, index: number) => (
                        <img 
                            src={image}
                            key={index}
                            alt={'carousel'}
                            className={index === currentIndex ? 'move' : 'carousel-image'}
                        />
                    ))
                }
            </div>

            <img
                src={imageData[prevIndex]}
                alt={'carousel'}
                className='carousel-main-image'
            /> 
        </div>
    )
}

export default Carousel;
