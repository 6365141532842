import './CarrouselBox.scss';

import foto1 from '../../../assets/footer/footer-1.png';
import foto2 from '../../../assets/footer/footer-2.png';
import foto3 from '../../../assets/footer/footer-3.png';
import foto4 from '../../../assets/footer/footer-4.png'

const CarrouselBox = () => {
  return (
    <div className='footer-carrousel-box'>
        <div className='footer-image first'>
            <img 
                src={foto1}
                alt={'footer'}
            />
        </div>

        <div className='footer-image second'>
            <img 
                src={foto2}
                alt={'footer'}
            />
        </div>

        <div className='footer-image third'>
            <img 
                src={foto3}
                alt={'footer'}
            />
        </div>

        <div className='footer-image fourth'>
            <img 
                src={foto4}
                alt={'footer'}
            />
        </div>
    </div>
  )
}

export default CarrouselBox;
