import './BottomNav.scss';
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Link as ScrollLink } from 'react-scroll';
import classNames from "classnames";
import { useMediaQuery } from '../../hooks/useMediaQuery';


const BottomNav = (props: any) => {
    const isSmallScreen = useMediaQuery('(max-width: 600px)');

     // MixBlendMode Logic For Header

     const [isVisible, setVisible] = useState(false);

     useEffect(() => {
         const handleScroll = () => {
            if (window.innerWidth > 800) {
                if (window.scrollY > 250 && window.scrollY < (document.body.scrollHeight - 1800)) {
                    setVisible(true);
                } else {
                    setVisible(false);
                }
            } else {
                if (window.scrollY > 320 && window.scrollY < (document.body.scrollHeight - 1200)) {
                    setVisible(true);
                } else {
                    setVisible(false);
                }
            }   
         };
 
         window.addEventListener('scroll', handleScroll);
 
         return () => {
             window.removeEventListener('scroll', handleScroll);
         };
     }, [props.active]);

    // Styles

    const getNavItemClass = (text: string) => {
        return classNames('nav-item', {
            active: props.active === text.toLowerCase()
        });
    };

    const bottomNavStyles = classNames('portfolio-bottom-nav', {
        visible: isVisible
    });

    return (
        <div className={bottomNavStyles}>
                <Link to='/portfolio/weddings'>
                    <span className={getNavItemClass('weddings')}>weddings</span>
                </Link>
                
                <Link to='/portfolio/couples'>
                    <span className={getNavItemClass('couples')}>Couples</span>
                </Link>
                
                <Link to='/portfolio'>
                    <span className={getNavItemClass('all')}>All</span>
                </Link>

                {/* {
                    !isSmallScreen && (
                        <ScrollLink smooth spy to='contacts'>
                            <span className={getNavItemClass('')}>contacts</span>
                        </ScrollLink>
                    )
                } */}
                
        </div>
    )
}

export default BottomNav
